import instance, { loginInstance } from "../instance";

export const checkoutToStripePage = async (data) => {
  try {
    const response = await loginInstance.post(`/api/company/register`, data);
    return response;
  } catch (error) {
    console.error("Error in checkoutToStripePage:", error);
    return error;
  }
};

export const searchCompany = async (companyName) => {
  try {
    const response = await loginInstance.post(
      `/api/searched-companies`,
      companyName
    );
    return response;
  } catch (error) {
    console.error("Error in searchCompany:", error);
    return error;
  }
};

export const retrieveChekoutSession = async (data) => {
  try {
    const response = await loginInstance.post(
      `api/stripe/session/update`,
      data
    );
    return response;
  } catch (error) {
    console.error("Error in searchCompany:", error);
    return error;
  }
};

export const searchPersonDetails = async (searchedName) => {
  try {
    const response = await instance.get(
      `/api/person-details?filters[forename][$eq]=${searchedName}`
    );
    return response;
  } catch (error) {
    console.error("Error in searchCompany:", error);
    return error;
  }
};

export const sendOtp = async (data) => {
  try {
    const response = await loginInstance.post(`/api/verify-mobiles/send-otp`, data);
    return response;
  } catch (error) {
    console.error("Error in searchCompany:", error);
    return error;
  }
};

export const verifyOtp = async (data) => {
  try {
    const response = await loginInstance.post(
      `/api/verify-mobiles/verify-otp`,
      data
    );
    return response;
  } catch (error) {
    console.error("Error in searchCompany:", error);
    return error;
  }
};
