import React, { useState, useEffect } from "react";
import BarclaysLogo from "../../assets/logo/BarclaysLogo.svg";
import RevolutLogo from "../../assets/logo/Revolut.svg";
import MonzoLogo from "../../assets/logo/MonzoLogo.svg";
import {
  Box,
  Button,
  Typography,
  FormControlLabel,
  Radio,
  Grid,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import {
  BankingDetailsApi,
  getBankingDetailsApi,
  editBankingDetailsApi,
} from "../../apiServices/services/DocumentsServices";
import { getDirectorsApi } from "../../apiServices/services/OfficeServices";
import { updateFormStatus } from "../../apiServices/services/ProfileServices";
import { setName } from "../../store/PackageSlice";

import CompletionTick from "../../assets/logo/completionTick.svg";

const Banking = ({ onContinue, onBack }) => {
  const [formData, setFormData] = useState({
    isisBarclays: false,
    isMonzo: false,
    isRevolut: false,
    isContactBankRequired: false,
    isFormCompleted: false,
    userId: localStorage.getItem("userId"),
  });

  const [personNames, setPersonNames] = useState([]);
  const [userId, setUserId] = useState(0);
  const [selectedBank, setSelectedBank] = useState(
    formData.isisBarclays
      ? "barclays"
      : formData.isMonzo
      ? "monzo"
      : formData.isRevolut
      ? "revolut"
      : ""
  );

  useEffect(() => {
    getBankingDetailsApi(formData.userId).then((response) => {
      if (response?.attributes?.results[0]) {
        setUserId(response?.attributes?.results[0].id);
        setFormData((prevState) => ({
          ...prevState,
          isisBarclays: response?.attributes?.results[0].isisBarclays,
          isMonzo: response?.attributes?.results[0].isMonzo,
          isRevolut: response?.attributes?.results[0].isRevolut,
          isFormCompleted: response?.attributes?.results[0].isFormCompleted,
        }));
      } else if (!response?.attributes.results[0]) {
        setFormData((prevState) => ({
          ...prevState,
          isisBarclays: true,
        }));
      }
    });
  }, []);

  const [contactAddress, setContactAddress] = useState("service");
  const [emailError, setEmailError] = useState(false);

  const handleAddressChange = (e) => {
    setContactAddress(e.target.value);
  };

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleCheckboxChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.checked });
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
    if (name === "email") {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(value)) {
        setEmailError(true);
      } else {
        setEmailError(false);
      }
    }
  };

  const handleSubmit = () => {
    if (emailError) {
      alert("Please enter valid email address before proceeding");
      return;
    }

    if (!formData.isFormCompleted) {
      BankingDetailsApi({ ...formData, isFormCompleted: true }).then((res) => {
        if (res.status === 200) {
          updateFormStatus(formData.userId, {
            isBankingDetailsCompleted: true,
          }).then((res) => {
            if (res.status === 200) {
              onContinue();
              localStorage.setItem("activeForm", "isBankingDetailsCompleted");
            } else {
            }
          });
        }
      });
    } else {
      editBankingDetailsApi(userId, { ...formData }).then((res) => {
        if (res.status === 200) {
          onContinue();
          localStorage.setItem("activeForm", "isBankingDetailsCompleted");
        }
      });
    }
  };

  const handleClick = (bank) => {
    if (bank === "barclays") {
      if (formData.isisBarclays) {
        setFormData((prevData) => ({
          ...prevData,
          isisBarclays: false,
        }));
      } else {
        setFormData((prevData) => ({
          ...prevData,
          isisBarclays: true,
        }));
      }
    }
    if (bank === "revolut") {
      if (formData.isRevolut) {
        setFormData((prevData) => ({
          ...prevData,
          isRevolut: false,
        }));
      } else {
        setFormData((prevData) => ({
          ...prevData,
          isRevolut: true,
        }));
      }
    }
    if (bank === "monzo") {
      if (formData.isMonzo) {
        setFormData((prevData) => ({
          ...prevData,
          isMonzo: false,
        }));
      } else {
        setFormData((prevData) => ({
          ...prevData,
          isMonzo: true,
        }));
      }
    }
  };

  return (
    <Box
      sx={{
        padding: 4,
        backgroundColor: "#fff",
        borderRadius: 2,
        boxShadow: 1,
        margin: "auto",
      }}
    >
      <Typography
        variant="h6"
        sx={{
          color: "1C2434",
          fontWeight: "bold",
          fontSize: "1.25rem",
        }}
      >
        Choose the right Business Bank for you
      </Typography>
      <Typography
        variant="body1"
        sx={{
          fontWeight: "medium",
          fontSize: "1rem",
          color: "2F2D3B",
          mb: 3,
          mt: 2,
          maxWidth: "750px",
          width: "100%",
        }}
      >
        Below we have three recommended Business Bank partners, who will help
        you set up your account seamlessly. Untick the box if you don not wish
        to be contacted.
      </Typography>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Box
          sx={{
            padding: 4,
            backgroundColor: "#fff",
            borderRadius: 2,
            boxShadow: 1,
            margin: "auto",
            marginRight: "25rem",
            cursor: "pointer",
          }}
        >
          <Box
            sx={{
              display: "flex",
            }}
            onClick={() => handleClick("barclays")}
          >
            <Box
              sx={{
                pr: 2,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <FormControlLabel
                control={
                  <Radio
                    checked={formData.isisBarclays}
                    onChange={() => handleClick("barclays")}
                    value="barclays"
                    sx={{
                      transform: "scale(1.5)",
                    }}
                    icon={<RadioButtonUncheckedIcon sx={{ fontSize: 26 }} />}
                    checkedIcon={
                      <Box>
                        <img
                          width={"63px"}
                          style={{ marginRight: "7px" }}
                          src={CompletionTick}
                        />
                      </Box>
                    }
                  />
                }
                label=""
              />
            </Box>
            <Box>
              <Typography
                variant="h6"
                sx={{
                  color: "1C2434",
                  fontWeight: "bold",
                  fontSize: "1.25rem",
                }}
              >
                Barclays Business Bank
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontWeight: "medium",
                  fontSize: "1rem",
                  color: "2F2D3B",
                  mb: 3,
                  mt: 2,
                }}
              >
                Get 24/7 business banking support and fraud protection from one
                of the largest business bank providers in the UK. currencies.
              </Typography>
            </Box>
            <Box sx={{ ml: 6 }}>
              <img width={"180px"} src={BarclaysLogo} />
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            padding: 4,
            backgroundColor: "#fff",
            borderRadius: 2,
            boxShadow: 1,
            margin: "auto",
            marginRight: "25rem",
            cursor: "pointer",
            mt: 4,
          }}
        >
          <Box
            sx={{
              display: "flex",
            }}
            onClick={() => handleClick("revolut")}
          >
            <Box
              sx={{
                pr: 2,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <FormControlLabel
                control={
                  <Radio
                    checked={formData.isRevolut}
                    onChange={() => handleClick("revolut")}
                    value="revolut"
                    sx={{
                      transform: "scale(1.5)",
                    }}
                    icon={<RadioButtonUncheckedIcon sx={{ fontSize: 26 }} />}
                    checkedIcon={
                      <Box>
                        <img
                          width={"63px"}
                          style={{ marginRight: "7px" }}
                          src={CompletionTick}
                        />
                      </Box>
                    }
                  />
                }
                label=""
              />
            </Box>
            <Box>
              <Typography
                variant="h6"
                sx={{
                  color: "1C2434",
                  fontWeight: "bold",
                  fontSize: "1.25rem",
                }}
              >
                {/* Barclays Business Bank */}
                Revolut Business Bank
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontWeight: "medium",
                  fontSize: "1rem",
                  color: "2F2D3B",
                  mb: 3,
                  mt: 2,
                }}
              >
                {/* Get 24/7 business banking support and fraud protection from one
                of the largest business bank providers in the UK. */}
                Get access to a leading challenger bank, which includes the
                flexibility to make international payments in over 25
                currencies.
              </Typography>
            </Box>
            <Box sx={{ ml: 6 }}>
              {/* <img width={"180px"} src={BarclaysLogo} /> */}
              <img width={"135px"} src={RevolutLogo} />
            </Box>
          </Box>
        </Box>
        <Box />
        <Box
          sx={{
            padding: 4,
            backgroundColor: "#fff",
            borderRadius: 2,
            boxShadow: 1,
            margin: "auto",
            marginRight: "25rem",
            cursor: "pointer",
            mt: 4,
          }}
        >
          <Box
            sx={{
              display: "flex",
            }}
            onClick={() => handleClick("monzo")}
          >
            <Box
              sx={{
                pr: 2,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <FormControlLabel
                control={
                  <Radio
                    checked={formData.isMonzo}
                    onChange={() => handleClick("monzo")}
                    value="monzo"
                    sx={{
                      transform: "scale(1.5)",
                    }}
                    icon={<RadioButtonUncheckedIcon sx={{ fontSize: 26 }} />}
                    checkedIcon={
                      <Box>
                        <img
                          width={"80px"}
                          style={{ marginRight: "7px" }}
                          src={CompletionTick}
                        />
                      </Box>
                    }
                  />
                }
                label=""
              />
            </Box>
            <Box>
              <Typography
                variant="h6"
                sx={{
                  color: "1C2434",
                  fontWeight: "bold",
                  fontSize: "1.25rem",
                }}
              >
                Monzo Business Bank
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontWeight: "medium",
                  fontSize: "1rem",
                  color: "2F2D3B",
                  mb: 3,
                  mt: 2,
                  // width:"400px"
                }}
              >
                Get 12 months of Monzo Business Pro features for free. Including
                invoicing, accounting software integrations and international
                payments.
              </Typography>
            </Box>
            <Box sx={{ ml: 6 }}>
              <img width={"190px"} src={MonzoLogo} />
            </Box>
          </Box>
        </Box>
        <Typography sx={{ mt: 3, fontWeight: "medium" }}>
          Please be aware, Rise Formations may receive commission from the
          chosen Banking Partners selected if you proceed.
        </Typography>
        <Box />
      </Box>

      <Grid item xs={12}>
        <Box mt={2} display="flex">
          <Button
            sx={{
              marginTop: "1rem",
              textTransform: "none",
              backgroundColor: "#ffffff",
              color: "#cfcfcf",
              border: "1px solid #cfcfcf",
              borderRadius: "1rem",
              padding: "0.4rem 1.5rem",
              fontWeight: 600,
              "&:hover": {
                color: "#2069F8",
                border: "1px solid #2069F8",
              },
            }}
            onClick={onBack}
          >
            Previous
          </Button>
          <Button
            sx={{
              marginTop: "1rem",
              marginLeft: "0.5rem",
              borderRadius: "1rem",
              backgroundColor: "#2069F8",
              color: "#ffffff",
              fontWeight: 700,
              padding: "0.5rem 1.5rem",
              textTransform: "none",
              width: "max-content",
              "&:hover": {
                backgroundColor: "#2069F8",
              },
            }}
            onClick={handleSubmit}
          >
            Continue
          </Button>
        </Box>
      </Grid>
    </Box>
  );
};

export default Banking;
