import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  Button,
  useTheme,
  useMediaQuery,
} from "@mui/material";

const packageData = [
  {
    packageName: "Starter Package",
    price: "0",
    additionalFee: "+ £39 Companies House Fee",
    features: [
      "Company Incorporation",
      "Incorporation Certificate",
      "Shareholder Certificates",
      "Memorandum of Articles",
      "Articles of Association",
      "Company Register",
      "1st Confirmation Statement Free",
      "Business Bank Account Setup",
      "Free Business Consultation",
    ],
  },
  {
    packageName: "Starter Plus Package",
    price: "79.00",
    additionalFee: "+ £39 Companies House Fee",
    features: [
      "Company Incorporation",
      "Incorporation Certificate",
      "Shareholder Certificates",
      "Memorandum of Articles",
      "Articles of Association",
      "Company Register",
      "1st Confirmation Statement Free",
      "Business Bank Account Setup",
      "Free Business Consultation",
      "Central London Registered Address",
      "12-months free Company Secretarial Service",
    ],
  },
  {
    packageName: "Tech Startup Package",
    price: "199",
    additionalFee: "+ £39 Companies House Fee",
    features: [
      "Company Incorporation",
      "Incorporation Certificate",
      "Shareholder Certificates",
      "Memorandum of Articles",
      "Articles of Association",
      "Company Register",
      "1st Confirmation Statement Free",
      "Business Bank Account Setup",
      "Free Business Consultation",
      "Central London Registered Address",
      "12-months free Company Secretarial Service",
      "$5,000 AWS Credits",
      "Free Software Development Consultation",
    ],
  },
];

const Package = ({ handleClickSetSix }) => {
  const navigate = useNavigate();
  const [selectedPackage, setSelectedPackage] = useState(packageData[0]);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleCardClick = (item) => {
    setSelectedPackage(item);
  };

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"center"}
      alignItems="flex-start"
      sx={{ paddingLeft: "1rem", paddingTop:'0.5rem' }}
    >
      <Typography
        variant="h3"
        sx={{
          fontWeight: "700",
          fontSize: "1.7rem",
          marginBottom: "1.5rem",
          color: "#0F0049",
          textAlign: "left",
          padding: isSmallScreen ? "0 1rem" : "0 2rem",
        }}
      >
        Choose your company formation package
      </Typography>
      <Box
        display="flex"
        flexWrap="wrap"
        justifyContent="flex-start"
        width="100%"
        gap={2}
        sx={{
          padding: isSmallScreen ? "0 1rem" : "0 2rem",
        }}
      >
        {packageData.map((item, index) => (
          <Box
            key={index}
            onClick={() => handleCardClick(item)}
            backgroundColor="white"
            sx={{
              position: "relative", // Important for placing the "Recommended" badge
              cursor: "pointer",
              border:
                selectedPackage.packageName === item.packageName
                  ? "2px solid #1f69f7"
                  : "1px solid #E0DFE5",
              borderRadius: "1rem",
              paddingLeft: "1rem",
              pt: 1,
              width: isSmallScreen ? "100%" : "30%",
              boxShadow:
                selectedPackage.packageName === item.packageName
                  ? "0 4px 8px rgba(0,0,0,0.1)"
                  : "none",
              transition: "all 0.3s ease",
            }}
          >
            {/* Render "Recommended" badge for Starter Plus Package */}
            {item.packageName === "Starter Plus Package" && (
              <Typography
                sx={{
                  position: "absolute",
                  top: "-10px", // Adjust to place above the card
                  left: "50%",
                  transform: "translateX(-50%)",
                  backgroundColor: "#1f69f7",
                  color: "white",
                  fontWeight: 600,
                  fontSize: "0.8rem",
                  borderRadius: "0.5rem",
                  padding: "0.25rem 1rem",
                  textAlign: "center",
                }}
              >
                Recommended
              </Typography>
            )}
            <Typography
              variant="h5"
              sx={{
                fontWeight: 600,
                fontSize: "1.3rem",
                // marginBottom: "0.5rem",
                mt: "0.5rem",
              }}
            >
              {item.packageName}
            </Typography>
            <Typography
              variant="h4"
              sx={{ fontWeight: 700, fontSize: "2rem", color: "#1f69f7" }}
            >
              £{item.price}
            </Typography>
            <Typography
              variant="body2"
              sx={{
                fontSize: "1rem",
                marginBottom: "0.7rem",
                fontWeight: 600,
              }}
            >
              {item.additionalFee}
            </Typography>
            <Box>
              {item.features.map((feature, featureIndex) => (
                <Typography
                  key={featureIndex}
                  variant="body2"
                  sx={{ fontSize: "0.9rem", marginBottom: "0.25rem" }}
                >
                  • {feature}
                </Typography>
              ))}
            </Box>
          </Box>
        ))}
      </Box>

      <Button
        variant="contained"
        onClick={() => handleClickSetSix(selectedPackage)}
        sx={{
          marginTop: "1.1rem",
          marginLeft: "2rem",
          backgroundColor: "#1f69f7",
          color: "#ffffff",
          padding: "0.5rem 2rem",
          borderRadius: "1rem",
          textTransform: "none",
          fontWeight: 700,
          fontSize: "1rem",
          "&:hover": {
            backgroundColor: "#1859c6",
          },
          alignSelf: "flex-start",
        }}
      >
        Continue
      </Button>
    </Box>
  );
};

export default Package;
